import { Workplan } from '@/wpt-lib/interfaces/Workplan';
import React, { useCallback, useState } from 'react';
import { useDeleteRequest, useGetRequest, usePatchRequest, usePostRequest } from '@/utils/useStatefulRequests';
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect';
import CommentsList, { CommentsListProps } from '@/components/pages/workplan/comment/CommentsList';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { LoadingSpinner } from '@/components';
import { Comment, CommentRequest } from '@/wpt-lib/interfaces/Comment';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { EntityChange } from '@/wpt-lib/interfaces/EntityChange';
import {
    WorkplanComment,
    WorkplanCommentSection,
    mapWorkplanCommentSectionToLabel,
} from '@/wpt-lib/interfaces/WorkplanComment';
import AutoExpandTextArea from '@/components/pages/workplan/comment/AutoExpandTextArea';
import InfoTooltip from '@/components/Tooltip/InfoTooltip';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';

interface CommentsTileProps<T> {
    isReadonly: boolean;
    baseApiUrl: string;
    apiParams?: object;
    currentAssignment?: Assignment;
    workplan: Workplan;
    version?: number;
    section?: WorkplanCommentSection;
    system?: Comment[];
    filter?: CommentsListProps<T>['filter'];
    data?: Partial<CommentRequest>;
    onCreate?: () => void;
    placeholder?: string;
}

export interface SelectedCommentAction<T> {
    type: 'edit' | 'delete' | 'deleteError' | 'editError';
    comment?: T;
}

export const CommentsTile = <T extends Comment>({
    workplan,
    version,
    isReadonly,
    baseApiUrl,
    currentAssignment,
    section,
    apiParams,
    system,
    filter,
    data = {},
    onCreate,
    placeholder,
}: CommentsTileProps<T>) => {
    const [comment, setComment] = useState('');

    const [selectedComment, setSelectedComment] = useState<SelectedCommentAction<T & EntityChange> | undefined>(
        undefined,
    );
    const { makeRequest: createComment, error: addCommentError } = usePostRequest<T, CommentRequest>();
    const {
        makeRequest: getComments,
        error: getCommentsError,
        loading,
        responseData: commentsList,
    } = useGetRequest<(T & EntityChange)[]>();

    const { makeRequest: deleteComment, error: deleteCommentError } = useDeleteRequest<T>();

    const { makeRequest: editComment, error: updateCommentError } = usePatchRequest<T, CommentRequest>();

    const { supervisor, admin } = useNavigateOptions();

    const updateComment = useCallback(
        async (comment: T & EntityChange, newComment: string) => {
            try {
                await editComment(
                    `/${baseApiUrl}/comment/${comment.id}`,
                    { comment: newComment },
                    { params: { assignment: currentAssignment?.id } },
                );
                await getListOfComments();
            } catch (error) {
                setSelectedComment({
                    type: 'editError',
                    comment: comment,
                });
            }
        },
        [baseApiUrl, currentAssignment],
    );
    const getListOfComments = useCallback(async () => {
        try {
            setSelectedComment(undefined);
            await getComments(`/${baseApiUrl}/comment`, {
                params: {
                    assignment: currentAssignment?.id,
                    version: version,
                    section: section,
                    ...(apiParams || {}),
                },
            });
        } catch (error) {
            //handled by hook
        }
    }, [baseApiUrl, currentAssignment, version, section]);
    const removeComment = useCallback(
        async (comment: T & EntityChange) => {
            try {
                await deleteComment(`/${baseApiUrl}/comment/${comment.id}`, {
                    params: { assignment: currentAssignment?.id },
                });
                await getListOfComments();
            } catch (error) {
                setSelectedComment({
                    type: 'deleteError',
                    comment: comment,
                });
            }
        },
        [baseApiUrl, currentAssignment, getListOfComments],
    );
    const addNewComment = useCallback(
        async (section: WorkplanCommentSection | undefined) => {
            try {
                await createComment(
                    `/${baseApiUrl}/comment`,
                    { comment, section, ...data },
                    {
                        params: { assignment: currentAssignment?.id },
                    },
                );
                await getListOfComments();
                if (onCreate) onCreate();
                setComment('');
            } catch (error) {
                //handled by hook
            }
        },
        [currentAssignment, getListOfComments, comment, onCreate],
    );

    useAsyncEffect(async () => {
        if (currentAssignment) {
            await getListOfComments();
        }
    }, [currentAssignment, getListOfComments]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (getCommentsError) {
        return (
            <SimpleAlert variant={'left-accent'}>
                <Text>Cannot get list of comments, please refresh the page and try again</Text>
            </SimpleAlert>
        );
    }

    return (
        <Box>
            <CommentsList
                workplan={workplan}
                setSelectedComment={setSelectedComment}
                selectedComment={selectedComment}
                deleteError={deleteCommentError}
                editError={updateCommentError}
                onDelete={isReadonly ? undefined : removeComment}
                onEdit={isReadonly ? undefined : updateComment}
                comments={commentsList}
                currentAssignment={currentAssignment}
                system={system as (T & EntityChange & { readonly?: boolean })[]}
                filter={filter}
                placeholder={placeholder}
            />
            {!isReadonly && (
                <VStack alignItems={'flex-end'}>
                    <AutoExpandTextArea
                        data={comment}
                        placeholder="Enter your comment"
                        aria-label={(section ? mapWorkplanCommentSectionToLabel[section] : 'Workplan') + ' Comment'}
                        onChange={setComment}
                        onKeyDown={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter' && e.ctrlKey) {
                                addNewComment(section);
                            }
                        }}
                    />
                    <HStack>
                        <Button colorScheme={'blue'} isDisabled={!comment} onClick={() => addNewComment(section)}>
                            Save comment
                        </Button>
                        {data.stage === 'END_YEAR' && (supervisor || admin) && (
                            <>
                                <InfoTooltip
                                    tooltipLabel="Add Comment Tooltip"
                                    tooltipInfoText="During the end of year review, use this button to add a general comment for the staff member to view. Please note: comments will only be available once the workplan is endorsed following the faculty moderation process."
                                />
                                <Button
                                    colorScheme={'blue'}
                                    isDisabled={
                                        !comment ||
                                        !!commentsList?.find(
                                            (c) =>
                                                (c as unknown as WorkplanComment).section ===
                                                    WorkplanCommentSection.END_YEAR &&
                                                c.author?.id === currentAssignment?.id,
                                        )
                                    }
                                    onClick={() => addNewComment(WorkplanCommentSection.END_YEAR)}
                                >
                                    Add End of Year comment
                                </Button>
                                <InfoTooltip
                                    tooltipLabel="Add End of Year Comment Tooltip"
                                    tooltipInfoText="Once the staff workplan is finalised, please use this button to add the final workplan comment. This comment will be tagged and visible to the staff member following endorsement at the end of the year. You are  only able to add ONE end of year comment (this can be  edited or deleted and re-added if required)."
                                />
                            </>
                        )}
                    </HStack>
                    {!!addCommentError && (
                        <SimpleAlert variant={'left-accent'}>
                            <Text>Cannot add new comment, please refresh the page and try again</Text>
                        </SimpleAlert>
                    )}
                </VStack>
            )}
        </Box>
    );
};
