import React, { useState } from 'react';
import { Workplan } from '@/wpt-lib/interfaces/Workplan';
import { Button } from '@chakra-ui/react';
import Icons from '@/components/icons/Icons';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import DeleteWorkplanDialog from '@/components/DeleteWorkplan/DeleteWorkplanDialog';

const DeleteWorkplan = ({ workplan }: { workplan: Workplan }) => {
    const [workplanToDelete, setWorkplanToDelete] = useState<Workplan>();
    const { currentAssignment } = useAssignment();

    return (
        <>
            <Button
                leftIcon={<Icons.DeleteIcon />}
                onClick={() => setWorkplanToDelete(workplan)}
                rounded="none"
                variant="outline"
            >
                Delete Draft Workplan
            </Button>
            {currentAssignment && (
                <DeleteWorkplanDialog
                    workplan={workplanToDelete}
                    onClose={() => setWorkplanToDelete(undefined)}
                    currentAssignment={currentAssignment}
                />
            )}
        </>
    );
};

export default DeleteWorkplan;
