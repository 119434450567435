import React from 'react';
import { Dialog } from '@/components/Dialog';

interface DeleteRowAlertProps {
    deleteAction: () => Promise<void>;
    deleteError: unknown;
    isOpen: boolean;
    isDeleting: boolean;
    onClose: () => void;
    header: string;
    action: string;
    workplanType: string;
}

export const ExitFormAlert = ({
    deleteAction,
    deleteError,
    action,
    isDeleting,
    isOpen,
    onClose,
    header,
    workplanType,
}: DeleteRowAlertProps) => {
    const deleteRecord = async () => {
        await deleteAction();
        onClose();
    };

    return (
        <Dialog
            colorScheme={'blue'}
            dialogHeader={header}
            dialogBody={`Are you sure you want to discard ${action}? No data will be saved for this ${workplanType} if you choose to discard. To save your progress please select 'Save & Return to Dashboard' on the previous screen.`}
            errorMsg={deleteError ? 'Unable to rollback changes, please refresh the page and try again.' : undefined}
            dialogAction={deleteRecord}
            dialogConfirmLabel={'Exit'}
            isOpen={isOpen}
            isLoading={isDeleting}
            onClose={onClose}
        />
    );
};
