// 1. Import the extendTheme function
// 2. Extend the theme to include custom colors, fonts, etc
import { ChakraTheme, extendTheme } from '@chakra-ui/react';
// TODO:Change the range of red colour
// Tints generated from UTS Color palette and https://www.tailwindshades.com/
const colors: ChakraTheme['colors'] = {
    // Primary colour palette
    red: {
        50: '#FFEDEA',
        100: '#FFD7D1',
        200: '#FFAA9E',
        300: '#FF7D6B',
        400: '#FF5038',
        500: '#CC1400', // UTS Red
        550: '#FF9B00', //UTS Info
        600: '#D11900',
        700: '#9E1300',
        800: '#6B0D00',
        900: '#380700',
    },
    blue: {
        50: '#E2EAFD',
        100: '#CAD8FC',
        200: '#9AB4F9',
        300: '#6A90F6',
        400: '#3B6DF2',
        450: '#7ABFFF', // UTS Info light
        500: '#0F4BEB', // UTS Blue
        550: '#123286', // UTS Info dark
        600: '#0C3CBB',
        700: '#092C8B',
        800: '#061D5B',
        900: '#030E2B',
    },
    gray: {
        50: '#F8F9F9',
        100: '#EBEBEB', // Light Grey
        200: '#DCDCDC',
        300: '#CECECE',
        400: '#C0C0C0',
        500: '#B2B2B2', // UTS  Mid Grey
        600: '#909090',
        700: '#6F6F6F',
        800: '#4F4F4F',
        900: '#323232', // Dark Grey
    },
    // Restricted secondary colours
    // - for use with infographics
    green: {
        50: '#C5FDE0',
        100: '#ADFBD2',
        200: '#7CF9B8',
        300: '#4BF79E',
        400: '#1AF583',
        500: '#09D36A', // UTS Green
        600: '#07A251',
        700: '#057139',
        800: '#034020',
        900: '#010F08',
    },
    orange: {
        50: '#FFF5E5',
        100: '#FFEBCC',
        200: '#FFD799',
        300: '#FFC366',
        400: '#FFAF33',
        500: '#FF9B00', // UTS Orange
        600: '#CC7C00',
        700: '#995D00',
        800: '#663E00',
        900: '#331F00',
    },
    // - for use with navigation elements
    yellow: {
        50: '#FEFDDB',
        100: '#FEFCC7',
        200: '#FDFBA0',
        300: '#FCF978',
        400: '#FBF750',
        500: '#FAF528',
        600: '#E5DF05',
        700: '#AEAA04',
        800: '#777403',
        900: '#403F01',
    },
    // TRACK Infographics
    purple: {
        50: '#E8CEED',
        100: '#E2BFE8',
        200: '#D4A1DD',
        300: '#C683D2',
        400: '#B965C7',
        500: '#AB47BC',
        600: '#873695',
        700: '#62276C',
        800: '#3C1842',
        900: '#170919',
    },
    pink: {
        50: '#FFF8FA',
        100: '#FFE3ED',
        200: '#FFBAD2',
        300: '#FF92B7',
        400: '#FF699C',
        500: '#FF4081',
        600: '#FF085C',
        700: '#CF0046',
        800: '#970033',
        900: '#5F0020',
    },
    teal: {
        50: '#78FFED',
        100: '#63FFEA',
        200: '#3AFFE4',
        300: '#12FFDF',
        400: '#00E8C8',
        500: '#00BFA5',
        600: '#008775',
        700: '#004F44',
        800: '#001714',
        900: '#000000',
    },
    violet: {
        50: '#FFFFFF',
        100: '#F4F0FF',
        200: '#D6C7FF',
        300: '#B99FFF',
        400: '#9B76FF',
        500: '#7D4DFF',
        600: '#5415FF',
        700: '#3B00DC',
        800: '#2C00A4',
        900: '#1D006C',
    },
    lightblue: {
        50: '#FFFFFF',
        100: '#FFFFFF',
        200: '#F4FAFF',
        300: '#CCE6FF',
        400: '#A3D3FF',
        500: '#7ABFFF',
        600: '#42A4FF',
        700: '#0A89FF',
        800: '#006CD1',
        900: '#004F99',
    },
    darkgreen: {
        50: '#C5E2AD',
        100: '#BBDD9E',
        200: '#A6D27F',
        300: '#91C761',
        400: '#7CBD43',
        500: '#689F38',
        600: '#4D7629',
        700: '#324C1B',
        800: '#17230C',
        900: '#000000',
    },
    offWhite: '#FCFCFB',
};

const fonts: ChakraTheme['fonts'] = {
    body: 'NB Akademie Std, Arial, sans-serif',
    heading: 'NB International Pro, Arial, sans-serif',
    mono: 'NB Akademie Mono Std, monospace',
};

const styles: ChakraTheme['styles'] = {
    global: {
        body: {
            color: 'black',
            backgroundColor: 'gray.200',
        },
    },
};

const components: ChakraTheme['components'] = {
    Form: {
        baseStyle: {
            // Make helper text darker for better contrast
            helperText: {
                color: 'gray.700',
            },
        },
    },
    Button: {
        variants: {
            solid: ({ colorScheme }) => ({
                color: ['gray'].includes(colorScheme) ? 'black' : 'white',
            }),
        },
        baseStyle: {
            m: '5px',
            outlineColor: 'transparent',
            outlineStyle: 'dashed',
            outlineWidth: 3,
            transition: 'outline-color 0.25s',
            _focus: {
                boxShadow: 'none',
                outlineColor: 'black',
            },
            _disabled: {
                cursor: 'not-allowed',
            },
        },
    },
    Link: {
        baseStyle: {
            color: 'blue.500',
        },
    },
    Checkbox: {
        baseStyle: {
            control: {
                outlineColor: 'transparent',
                outlineStyle: 'solid',
                outlineWidth: 2,
                outlineOffset: 2,
                transition: 'outline-color 0.25s',
                _focus: {
                    boxShadow: 'none',
                    outlineColor: 'blue',
                },
            },
        },
    },
    Radio: {
        baseStyle: {
            control: {
                outlineColor: 'transparent',
                outlineStyle: 'solid',
                outlineWidth: 2,
                outlineOffset: 2,
                transition: 'outline-color 0.25s',
                _focus: {
                    boxShadow: 'none',
                    outlineColor: 'blue',
                },
            },
        },
    },
    Tabs: {
        baseStyle: {
            tab: {
                '&:not(:disabled):focus, &:not(:disabled):hover': {
                    background: 'gray.200',
                },
            },
        },
    },
    Select: {
        baseStyle: {
            field: {
                '> option, > optgroup': {
                    background: 'unset',
                },
            },
        },
    },
    Table: {
        variants: {
            striped: {
                thead: {
                    th: {
                        background: '#FCFCFB',
                        td: {
                            background: '#FCFCFB',
                        },
                    },
                },
                tbody: {
                    tr: {
                        '&:nth-of-type(odd)': {
                            background: '#f1f1f1',
                            td: {
                                background: '#f1f1f1',
                            },
                        },
                        '&:nth-of-type(even)': {
                            background: '#fcfcfb',
                            td: {
                                background: '#fcfcfb',
                            },
                        },
                    },
                },
            },
        },
    },
};

const customTheme = extendTheme({ colors, fonts, styles, components });
export default customTheme;
