import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useLayoutEffect, useState } from 'react';
import { GlobalAdminPanel } from '@/components/pages/Admin/GlobalAdminPanel';
import { LocalAdminPanel } from '@/components/pages/Admin/LocalAdminPanel';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';

interface UserAccessCustomisationProps {
    assignment: Assignment;
}

export const UserAccessCustomisation = (props: UserAccessCustomisationProps) => {
    const [currentTab, setCurrentTab] = useState<number>(0);
    const isGlobalAdmin = props.assignment.isGlobalAdmin;
    const isFacultyAdmin = props.assignment.adminFaculties?.length;
    const isEitherAdmin = isGlobalAdmin || isFacultyAdmin;
    const tabs = [
        {
            name: 'University',
            Content: GlobalAdminPanel,
            enabled: isGlobalAdmin,
        },
        {
            name: 'Local Area',
            Content: LocalAdminPanel,
            enabled: isEitherAdmin,
        },
    ];
    useLayoutEffect(() => {
        if (!tabs[currentTab].enabled) {
            setCurrentTab(
                Math.max(
                    tabs.findIndex((tab) => tab.enabled),
                    0,
                ),
            );
        }
    }, [props.assignment]);
    return (
        <Flex w="full" py={6}>
            <Tabs isLazy index={currentTab} onChange={setCurrentTab} w="full">
                <TabList px={6}>
                    {tabs.map((tab, i) => (
                        <Tab isDisabled={!tab.enabled} key={i}>
                            {tab.name}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {tabs.map((tab, i) => (
                        <TabPanel key={i}>
                            <tab.Content assignment={props.assignment} />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Flex>
    );
};
